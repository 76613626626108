import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import config from 'devextreme/core/config';
import * as Sentry from "@sentry/angular";
import { AppModule } from './app/app.module';
import { environment } from '@environments/environment';

const serverErrorsRegex = new RegExp(`assets`);

Sentry.init({
  dsn: environment.sentryDsn,
  integrations: [
    // Registers and configures the Tracing integration,
    // which automatically instruments your application to monitor its
    // performance, including custom Angular routing instrumentation
    Sentry.browserTracingIntegration(),
    // Registers the Replay integration,
    // which automatically captures Session Replays
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  //tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.0,
  replaysOnErrorSampleRate: 1.0,

  beforeSend: (event, hint) => {
    try {
      let hitUrl: any = hint.originalException;
      if (hitUrl?.url?.match(serverErrorsRegex).length > 0) {
        return null;
      }
      return event;
    } catch (e) {
      return event;
    }
  }
});

platformBrowserDynamic().bootstrapModule(AppModule, {
  ngZoneEventCoalescing: true
}).catch(err => console.error(err));
export const licenseKey = 'ewogICJmb3JtYXQiOiAxLAogICJjdXN0b21lcklkIjogIjM2YjFmNDRmLWYzY2EtNGFkMi1iMGNjLTM0ZTQwNTUyMTRmZCIsCiAgIm1heFZlcnNpb25BbGxvd2VkIjogMjQxCn0=.cByncHll2NHMiLpXrFtfA5ugZbOgGOhALjlFV20lprGEd2zplmqZn2wtziwmRfhelLV4U63uZXf/vTMfE0uXrp7usn/TMGxGe1R8jEndbXaZSG2k4j/rmATF4KuIpXEBB4NfPA==';

config({ licenseKey });
